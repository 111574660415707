import * as testUtils from './testUtils';
import * as formatters from './formatters';
import * as deviceType from './deviceType';
import * as pricings from './pricing';
import * as navigate from './Navigate';
import * as regExps from './regExps';
import * as formFieldValidation from './formFieldValidation';
import * as constants from './constants/formFieldValidation';
import * as enfants from './constants/enfantsACharge';
import * as emails from './email';
import * as mask from './masks';
import * as envConfig from './envConfig';
import * as file from './file';
import * as lists from './lists';
import compressImage from './imageResizer';
import * as evidenceUpload from './evidenceUpload';
import * as cookies from './cookies';
import * as rtkQueryUtils from 'store/rtkQueryUtils';
import * as authenticationUtils from './authentication';
import * as screenSize from './constants/screenSize';
import * as random from './random';
import * as data from './data';
export {
  Company,
  getCompanyName,
  isCMAG,
  isCMOI,
  isCreditModerne,
  isCafineoAG,
  isCafineoOI,
} from './Company';
export const { render, screen, waitFor, fireEvent } = testUtils;
export const {
  formatNumber,
  formatAsNumber,
  wholeWordCapitalize,
  convertAccents,
  ibanFormatter,
  bicFormatter,
  convertDate,
  removeSpace,
  capitalize,
  convertDateReverse,
} = formatters;
export const { detectMobileDevice, detectTabletDevice } = deviceType;
export const { getPricingsList } = pricings;
export const {
  getURLParams,
  isParcourSimulation,
  PATH_SIMULATION,
  getUrlParamsAsString,
} = navigate;
export const {
  validateEvidenceNumber,
  validateNationalityEvidence,
  validatePricing,
  validateBirthDate,
  isPersonFieldDisabled,
} = formFieldValidation;
export const { LOCATAIRE, PROPRIETAIRE_AVEC_PRET, AUTRES, LOGE_PAR_FAMILLE } =
  constants;
export const {
  nameRegExp,
  cityRegExp,
  alphaNumericWithAccentRegExp,
  addressRegExp,
  emailRegExp,
  fixPhoneRegExp,
  mobilePhoneRegExp,
  zipCodeRegexp,
  ibanRegExp,
  bicRegExp,
  purchaseOrderNumberRegExp,
  employerNameRegExp,
  FORMAT_NUMBER_PATTERN,
  NUMBER_PATTERN,
  LIMIT_TO_2_DECIMALS_PATTERN,
  LIMIT_TO_0_DECIMALS_PATTERN,
  bicSEPARegExp,
  ibanSEPARegExp,
  activityTypeRegExp,
  sirenRegExp,
  employeesNumberRegExp,
} = regExps;
export const {
  dateMask,
  dateMonthYearMask,
  zipCodeMask,
  phoneMask,
  sirenMask,
  employeesNumberMask,
} = mask;
export const { listeEnfantsACharge } = enfants;
export const { isEmailValide, nameBlackList, domainBlackList } = emails;
export const {
  getBffURL,
  getBffSimulationURL,
  isDocumentEnabled,
  getBffMentionLegaleURL,
  getEnv,
  getIhmVersion,
  getAuthenticationEnabled,
  getAuthenticationPath,
  getAuthenticationUri,
  getStrictMode,
  getMsBffBaseURL,
  getMsBffDomainPrefix,
} = envConfig;
export const {
  openPdfFile,
  getUniqueName,
  getRenamedFile,
  constructByteArray,
  uploadFile,
} = file;
export const { getUniqueId, getLabelFromList } = lists;
export { compressImage };
export const {
  containsRecto,
  containsDeletedImage,
  addImage,
  findCorrespondingImage,
} = evidenceUpload;
export const { setCookie, removeCookie } = cookies;
export const { getBffBaseUrl, getCommonPartDomain } = rtkQueryUtils;
export const {
  resetAuthenticationRequired,
  setAuthenticationRequired,
  isAuthenticationRequired,
  setAuthenticationEnabled,
  isAuthenticationEnabled,
} = authenticationUtils;
export const { MAX_WIDTH_SIZE, MIN_WIDTH_SIZE } = screenSize;
export const { generateRandomID } = random;
export const { hasValues } = data;
export { isSEPA, testSepaFrOrMc } from './AnalyseSepa';
