import { Modal, ModalContent, Icons } from '@components';
import {
  useDetectMobileDevice,
  useNavigate,
  useParcoursType,
  useRedirection,
} from '@hooks';
import {
  useAppSelector,
  selectConfiguration,
  selectError,
  useAppDispatch,
  clearSimulationExceptBornesAmount,
  resetError,
} from '@store';

import * as messages from './messages';
import {
  BlockBtnCopy,
  BlockCopy,
  BlockTextCopy,
  Btn,
  TextCopy,
  TextCopyBtn,
} from './styles';
import { useEffect, useState } from 'react';
import { IState } from 'store/error/errorTypes';

const ErrorComponent = ({
  children,
  resetErrorBoundary,
}: {
  children: any;
  resetErrorBoundary: () => void;
}): React.ReactElement => {
  const { redirigerAccueil } = useRedirection();
  const { requestId, vendorId, souscriptionId } =
    useAppSelector(selectConfiguration);
  const error = useAppSelector(selectError);

  const [isCopy, setIsCopy] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(true);
  const [errorInformations, setErrorInformations] = useState<IState>();
  const { isMobileScreenSize } = useDetectMobileDevice();
  const { isParcoursNominal } = useParcoursType();

  const usedRequestId = requestId ?? 'unknown';
  const isSimu = location.hash.startsWith('#/simulation');
  const { navigate } = useNavigate();
  const dispatch = useAppDispatch();
  const { isParcoursSwitchDevice } = useParcoursType();

  const goToCreateSimulation: () => void = () => {
    setIsVisible(false);
    resetErrorBoundary();
    sessionStorage.removeItem('form-offer');
    dispatch(clearSimulationExceptBornesAmount());
    navigate('/simulation', { state: { isNavButton: true } });
  };

  useEffect(() => {
    if (error?.code) {
      setErrorInformations(error);
      // Affichage de l'erreur + envoi des logs vers le MS sous
      console.error('Error dans ErrorComponent => ', error);
      dispatch(resetError(error));
    }
  }, [error]);

  const copyError = () => {
    const copy = {
      codeHttp: errorInformations?.status,
      service: errorInformations?.service,
      erreur: errorInformations?.code,
      description: errorInformations?.description,
      requestId: usedRequestId,
      numeroPointDeVente: vendorId,
      numeroSouscription: souscriptionId,
      date: errorInformations?.timestamp,
      donneesInvalides: errorInformations?.invalidData,
    };
    navigator.clipboard.writeText(JSON.stringify(copy).replace(/,/gi, '\n'));
    setIsCopy(true);
  };

  return (
    <>
      {children}
      <Modal isVisible={isVisible}>
        <ModalContent
          icon={<Icons.CloudIcon />}
          title={messages.TITLE}
          text={
            <>
              {isParcoursSwitchDevice()
                ? messages.REFUSE_CONTACT_MOBILE_DEVICE
                : messages.REFUSE_CONTACT}
              {usedRequestId && (
                <BlockCopy onClick={() => copyError()}>
                  <BlockTextCopy>
                    <TextCopy isCopy={isCopy}>
                      {messages.REQUESTID_HANDLER}
                      {usedRequestId}
                    </TextCopy>
                  </BlockTextCopy>
                  <BlockBtnCopy>
                    {!isMobileScreenSize() && (
                      <TextCopyBtn isCopy={isCopy}>Copier</TextCopyBtn>
                    )}
                    <Btn>
                      <Icons.CopyFile />
                    </Btn>
                  </BlockBtnCopy>
                </BlockCopy>
              )}
              {isParcoursSwitchDevice() && messages.MESSAGE_COPY_ERROR_CODE}
            </>
          }
          primaryButtonLabel={isParcoursNominal() ? messages.BACK_BUTTON : ''}
          onPrimaryButtonClick={
            isSimu ? goToCreateSimulation : redirigerAccueil
          }
        />
      </Modal>
    </>
  );
};

export default ErrorComponent;
