import * as messages from './messages';
import { Title, RadioList } from '@components';
import { FormWrapper, ItemWrapper, TitleWrapper } from './styles';
import { useFormContext, Controller } from 'react-hook-form';
import { listeEnfantsACharge, hasValues } from '@utils';
import useInformationsForm from '../useInformationsForm';
import { personTypes, selectPerson, useAppSelector } from '@store';
import { useValidateInput } from '@hooks';
import { useEffect } from 'react';

interface IProps {
  role: string;
}

const FamilySituation: React.FC<IProps> = ({ role }) => {
  const { control } = useFormContext();
  const { shouldValidateInput } = useValidateInput();

  const { listSituationsFamiliale } = useInformationsForm({ role });

  const persons = useAppSelector(selectPerson);

  useEffect(() => {
    if (
      !persons?.emprunteur?.familySituation ||
      !hasValues(persons?.emprunteur?.familySituation)
    ) {
      return;
    }
    shouldValidateInput(
      'inputMaritalStatus' + suffixRole,
      persons?.emprunteur?.familySituation?.maritalStatus
    );
    shouldValidateInput(
      'childrenNb' + suffixRole,
      persons?.emprunteur?.familySituation?.childrenNb
    );
  }, [persons]);

  const person =
    role === personTypes.RoleCd.BORROWER
      ? persons?.emprunteur
      : persons?.coemprunteur;

  const suffixRole = '_' + role;

  return (
    <FormWrapper>
      <TitleWrapper>
        <Title level={2}>{messages.CONTAINER_TITLE}</Title>
      </TitleWrapper>

      <ItemWrapper>
        <Controller
          control={control}
          name={'inputMaritalStatus' + suffixRole}
          rules={{
            required: true,
          }}
          defaultValue={person?.familySituation?.maritalStatus}
          render={({
            field: { onChange, value, name, ref },
            fieldState: { invalid },
          }) => (
            <RadioList
              name={name}
              label={messages.FAMILY_SITUATION}
              onChange={onChange}
              value={value}
              valueList={listSituationsFamiliale?.map((item) => item.id) ?? []}
              options={listSituationsFamiliale ?? []}
              isBig
              isValid={!invalid}
              inputRef={ref}
            />
          )}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Controller
          control={control}
          name={'childrenNb' + suffixRole}
          rules={{
            required: true,
          }}
          defaultValue={person?.familySituation?.childrenNb}
          render={({
            field: { onChange, value, name, ref },
            fieldState: { invalid },
          }) => (
            <RadioList
              name={name}
              label={messages.DEPENDENT_CHILD_NUMBER}
              onChange={onChange}
              value={value}
              valueList={listeEnfantsACharge?.map((item) => item.id) ?? []}
              options={listeEnfantsACharge}
              isBig
              isValid={!invalid}
              inputRef={ref}
            />
          )}
        />
      </ItemWrapper>
    </FormWrapper>
  );
};

export default FamilySituation;
