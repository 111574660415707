import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './errorTypes';

const getInitialState = () => {
  return { state: {}, oldState: {} } as { state: IState; oldState: IState };
};

// Initial State
export const initialState = getInitialState();

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError(errorState, action: PayloadAction<IState>) {
      errorState.state = {
        ...(action?.payload ?? getInitialState().state),
      };
    },
    resetError(errorState, action: PayloadAction<IState>) {
      errorState.oldState = {
        ...(action?.payload ?? getInitialState().state),
      };
      errorState.state = getInitialState().state;
    },
    clearError(errorState) {
      errorState.state = getInitialState().state;
      errorState.oldState = getInitialState().oldState;
    },
  },
});

export const selectError = (errorState: {
  error: { state: IState; oldState: IState };
}) => errorState.error.state;

export const selectOldStateError = (errorState: {
  error: { state: IState; oldState: IState };
}) => errorState.error.oldState;

export const { setError, resetError, clearError } = errorSlice.actions;

export default errorSlice.reducer;
