import {
  selectConfiguration,
  selectSimulation,
  updateSimulation,
  useSendSimulationDetailleeMutation,
  useAppDispatch,
  useAppSelector,
} from '@store';
import CreditForm from './CreditForm/CreditForm';
import EmprunteurForm from './EmprunteurForm/EmprunteurForm';
import SimulationButton from './SimulationButton';
import { Centering, Loader } from '@components';
import * as messages from './messages';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatAsNumber } from '@utils';
import { useEffect, useState } from 'react';

export enum CodesErreurSimulation {
  MONTANT_DES_MENSUALITES_NE_CORRESPOND_PAS_A_TARIFICATION_MSG = 'MONTANT_DES_MENSUALITES_NE_CORRESPOND_PAS_A_TARIFICATION_MSG',
  CALCUL_BORNES_KO = 'CALCUL_BORNES_KO',
}

const CreateSimulation: React.FC = () => {
  const configuration = useAppSelector(selectConfiguration);
  const { vendorId } = {
    ...configuration,
  };

  const simulationData = useAppSelector(selectSimulation);

  const navigate = useNavigate();
  const location = useLocation();

  const simulationId = location.state?.simulationId ?? 1;

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const submitData = methods.getValues();

  const [triggerSimulationDetaillee, resultSimulationDetaillee] =
    useSendSimulationDetailleeMutation();
  const [isErrorPricing, setIsErrorPricing] = useState(false);

  useEffect(() => {
    if (!resultSimulationDetaillee?.error) {
      return;
    }
    setIsErrorPricing(true);
  }, [resultSimulationDetaillee?.error]);

  useEffect(() => {
    if (!resultSimulationDetaillee?.data) {
      return;
    }
    updateStore(resultSimulationDetaillee);
    navigate(`/simulation-results`, {
      state: {
        simulationId: simulationId,
        existsSimu2: simulationData.simulation2,
        nbEmprunteurs: submitData.inputCoEmprunteurStatus !== '1' ? 2 : 1,
      },
    });
  }, [resultSimulationDetaillee?.data]);

  const [isPageReady, setIsPageReady] = useState(false);

  const buildRequest = () => {
    let ageEmp = 25;
    let ageCoEmp = undefined;
    if (submitData.inputEmprunteurStatus === '2') {
      ageEmp = 70;
    }

    if (submitData.inputCoEmprunteurStatus === '2') {
      ageCoEmp = 25;
    } else if (submitData.inputCoEmprunteurStatus === '3') {
      ageCoEmp = 70;
    }

    return {
      simulationRequest: {
        age_co_emprunteur: ageCoEmp,
        age_emprunteur: ageEmp,
        montant:
          submitData.inputSimulationLoanAmount === ''
            ? ''
            : formatAsNumber(submitData.inputSimulationLoanAmount),
        mensualite:
          submitData.inputSimulationMonthlyAmount === ''
            ? ''
            : formatAsNumber(submitData.inputSimulationMonthlyAmount),
        duree:
          submitData.inputSimulationMonthlyNumber === ''
            ? ''
            : formatAsNumber(submitData.inputSimulationMonthlyNumber),
        identifiant_tarification: submitData.inputSimulationSelectRate,
        numero_point_de_vente: vendorId!,
        report: submitData.inputSimulationSelectReport,
      },
    };
  };

  const dispatch = useAppDispatch();

  const { isValid, isSubmitting } = methods.formState;

  const isSubmitDisable = !isValid && !isSubmitting;

  const updateStore = (response: any) => {
    const buildSimulationResponse = () => {
      return {
        simulation: buildSimulation(response?.data),
      };
    };

    const buildSimulation = (simulationData: any) => {
      return {
        //Valeurs calculées
        montant: simulationData?.montant_demande,
        montantTotal: simulationData?.montant_demande,
        duree: simulationData?.duree,
        tarification: simulationData?.report,
        taeg: simulationData?.taeg,
        taeaGlobal: simulationData?.taea,
        tauxDebiteurFixe: simulationData?.taux_debiteur_dossier,
        premiereEcheance: simulationData?.report,
        fraisDossier: simulationData?.montant_frais_dossier,
        nombreEcheances: simulationData?.duree,
        montantEcheance: simulationData?.mensualite_calculee_sans_assurance,
        montantTotalDu: simulationData?.montant_total_du,
        horsAssurance: simulationData?.mensualite_calculee_sans_assurance,
        retenueVendeur: simulationData?.montant_retenue_vendeur,
        assurance: buildAssurance(simulationData),
      };
    };

    const buildAssurance = (simulationData: any) => {
      return {
        borrower: {
          totalInsuranceCost: {
            dimc: simulationData?.montant_assurance_emprunteur_dimc,
            dim: simulationData?.montant_assurance_emprunteur_dim,
            d: simulationData?.montant_assurance_emprunteur_d,
          },
          monthlyInsuranceCost: {
            dimc: simulationData?.montant_mensualite_assurance_emprunteur_dimc,
            dim: simulationData?.montant_mensualite_assurance_emprunteur_dim,
            d: simulationData?.montant_mensualite_assurance_emprunteur_d,
          },
          withInsuranceMonthlyPayment: {
            dimc: simulationData?.montant_mensualite_assurance_emprunteur_dimc
              ? simulationData?.montant_mensualite_assurance_emprunteur_dimc +
                simulationData?.mensualite_calculee_sans_assurance
              : undefined,
            dim: simulationData?.montant_mensualite_assurance_emprunteur_dim
              ? simulationData?.montant_mensualite_assurance_emprunteur_dim +
                simulationData?.mensualite_calculee_sans_assurance
              : undefined,
            d: simulationData?.montant_mensualite_assurance_emprunteur_d
              ? simulationData?.montant_mensualite_assurance_emprunteur_d +
                simulationData?.mensualite_calculee_sans_assurance
              : undefined,
          },
        },
        coborrower: {
          totalInsuranceCost: {
            dimc: simulationData?.montant_assurance_co_emprunteur_dimc,
            dim: simulationData?.montant_assurance_co_emprunteur_dim,
            d: simulationData?.montant_assurance_co_emprunteur_d,
          },
          monthlyInsuranceCost: {
            dimc: simulationData?.montant_mensualite_assurance_co_emprunteur_dimc,
            dim: simulationData?.montant_mensualite_assurance_co_emprunteur_dim,
            d: simulationData?.montant_mensualite_assurance_co_emprunteur_d,
          },
          withInsuranceMonthlyPayment: {
            dimc: simulationData?.montant_mensualite_assurance_co_emprunteur_dimc
              ? simulationData?.montant_mensualite_assurance_co_emprunteur_dimc +
                simulationData?.mensualite_calculee_sans_assurance
              : undefined,
            dim: simulationData?.montant_mensualite_assurance_co_emprunteur_dim
              ? simulationData?.montant_mensualite_assurance_co_emprunteur_dim +
                simulationData?.mensualite_calculee_sans_assurance
              : undefined,
            d: simulationData?.montant_mensualite_assurance_co_emprunteur_d
              ? simulationData?.montant_mensualite_assurance_co_emprunteur_d +
                simulationData?.mensualite_calculee_sans_assurance
              : undefined,
          },
        },
        borrowerCoborrower: {
          totalInsuranceCost: {
            dimc:
              simulationData?.montant_assurance_co_emprunteur_dimc &&
              simulationData?.montant_assurance_emprunteur_dimc
                ? simulationData?.montant_assurance_co_emprunteur_dimc +
                  simulationData?.montant_assurance_emprunteur_dimc
                : undefined,
            dim:
              simulationData?.montant_assurance_co_emprunteur_dim &&
              simulationData?.montant_assurance_emprunteur_dim
                ? simulationData?.montant_assurance_co_emprunteur_dim +
                  simulationData?.montant_assurance_emprunteur_dim
                : undefined,
            d:
              simulationData?.montant_assurance_co_emprunteur_d &&
              simulationData?.montant_assurance_emprunteur_d
                ? simulationData?.montant_assurance_co_emprunteur_d +
                  simulationData?.montant_assurance_emprunteur_d
                : undefined,
          },
          monthlyInsuranceCost: {
            dimc:
              simulationData?.montant_mensualite_assurance_co_emprunteur_dimc &&
              simulationData?.montant_mensualite_assurance_emprunteur_dimc
                ? simulationData?.montant_mensualite_assurance_co_emprunteur_dimc +
                  simulationData?.montant_mensualite_assurance_emprunteur_dimc
                : undefined,
            dim:
              simulationData?.montant_mensualite_assurance_co_emprunteur_dim &&
              simulationData?.montant_mensualite_assurance_emprunteur_dim
                ? simulationData?.montant_mensualite_assurance_co_emprunteur_dim +
                  simulationData?.montant_mensualite_assurance_emprunteur_dim
                : undefined,
            d:
              simulationData?.montant_mensualite_assurance_co_emprunteur_d &&
              simulationData?.montant_mensualite_assurance_emprunteur_d
                ? simulationData?.montant_mensualite_assurance_co_emprunteur_d +
                  simulationData?.montant_mensualite_assurance_emprunteur_d
                : undefined,
          },
          withInsuranceMonthlyPayment: {
            dimc:
              simulationData?.montant_mensualite_assurance_co_emprunteur_dimc &&
              simulationData?.montant_mensualite_assurance_emprunteur_dimc
                ? simulationData?.montant_mensualite_assurance_co_emprunteur_dimc +
                  simulationData?.montant_mensualite_assurance_emprunteur_dimc +
                  simulationData?.mensualite_calculee_sans_assurance
                : undefined,
            dim:
              simulationData?.montant_mensualite_assurance_co_emprunteur_dim &&
              simulationData?.montant_mensualite_assurance_emprunteur_dim
                ? simulationData?.montant_mensualite_assurance_co_emprunteur_dim +
                  simulationData?.montant_mensualite_assurance_emprunteur_dim +
                  simulationData?.mensualite_calculee_sans_assurance
                : undefined,
            d:
              simulationData?.montant_mensualite_assurance_co_emprunteur_d &&
              simulationData?.montant_mensualite_assurance_emprunteur_d
                ? simulationData?.montant_mensualite_assurance_co_emprunteur_d +
                  simulationData?.montant_mensualite_assurance_emprunteur_d +
                  simulationData?.mensualite_calculee_sans_assurance
                : undefined,
          },
        },
      };
    };

    const responseSimulation = buildSimulationResponse();
    const simulationById =
      simulationId === 2
        ? simulationData.simulation2
        : simulationData.simulation1;
    const updateSimulationObject: any = {};
    updateSimulationObject['simulation' + (simulationId === 2 ? '2' : '1')] = {
      enteredValue: {
        ...simulationById?.enteredValue!,
        borrower: submitData.inputEmprunteurStatus,
        coborrower: submitData.inputCoEmprunteurStatus,
      },

      calculatedValue: {
        ...simulationById?.calculatedValue!,
        amount: responseSimulation?.simulation?.montant, //montant
        totalAmount: responseSimulation?.simulation?.montantTotal, //montant
        duration: responseSimulation?.simulation?.duree, //duree
        scale: responseSimulation?.simulation?.tarification, //tarification
        taeg: responseSimulation?.simulation?.taeg, //taeg
        globalTaea: responseSimulation?.simulation?.taeaGlobal, //taeaGlobal
        vendorDeduction: responseSimulation?.simulation?.retenueVendeur, //retenueVendeur
        fixDebtorRate: responseSimulation?.simulation?.tauxDebiteurFixe, //tauxDebiteurFixe
        firstDeadline: responseSimulation?.simulation?.premiereEcheance, //premiereEcheance
        applicationFees: responseSimulation?.simulation?.fraisDossier, //fraisDossier
        deadlineNumber: responseSimulation?.simulation?.nombreEcheances, //nombeEcheances
        deadlineAmount: responseSimulation?.simulation?.montantEcheance, //montantEcheance
        totalDueAmount: responseSimulation?.simulation?.montantTotalDu, //montantTotalDu
        withoutInsurance: responseSimulation?.simulation?.horsAssurance, //horsAssurance
        insurance: responseSimulation?.simulation?.assurance,
      },
    };
    dispatch(updateSimulation(updateSimulationObject));
  };

  const onSubmit = () => {
    setIsErrorPricing(false);
    triggerSimulationDetaillee(buildRequest());
  };

  const getErrorPricing = () => {
    return isErrorPricing;
  };

  return (
    <Loader isLoading={!isPageReady} message={messages.LOADER}>
      <Centering>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CreditForm
              simulationId={simulationId}
              setIsPageReady={setIsPageReady}
              getErrorPricing={getErrorPricing}
            />
            <EmprunteurForm simulationId={simulationId} />
            <SimulationButton
              btnLabel={messages.BTN_RESULT_SIMU}
              goToResult={onSubmit}
              isDisabled={isSubmitDisable}
              isLoading={resultSimulationDetaillee?.isLoading}
            />
          </form>
        </FormProvider>
      </Centering>
    </Loader>
  );
};

export default CreateSimulation;
